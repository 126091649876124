<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item style="color: #fff">店铺管理</el-breadcrumb-item>
      <el-breadcrumb-item>店铺设置</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card style=" overflow: auto">
       
      <!-- 分类搜索 -->
     <el-form :model="formsetdate">
      <el-form-item label="店铺名称:" >
          <p>
            {{formsetdate.businessName}}
       </p>
          </el-form-item>
              <el-form-item label="店铺电话:" required>
           <el-col class="mr15" :span="3.3">
          <el-input size="mini"  v-model="formsetdate.businessPhone" ></el-input>  
        </el-col>
          </el-form-item>
          <el-form-item label="平台商品分类" required>
                <el-select
                  size="mini"
                 disabled
                  v-model="formsetdate.primaryCategoryId"
                  clearable
                  placeholder="一级分类"
              
                  class="mr15"
                  style="width: 150px"
                >
                  <el-option
                    v-for="(item, index) in redactondaroicroin"
                    :key="index"
                   :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <el-select
                  size="mini"
                  disabled
                  v-model="formsetdate.categoryId"
                  clearable
                  placeholder="二级分类"
                  class="mr15"
                  style="width: 150px"
                >
                  <el-option
                    v-for="(item, index) in  redactityStatus"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            <el-form-item label="营业时间:" required>
              <el-time-picker
                size="mini"
                is-range
                v-model="business"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                 @change="shopTime"
              >
              </el-time-picker>
            </el-form-item>
          <el-form-item label="店铺人均价格:" required>
            <el-col class="mr15" :span="3.3">
              <el-input size="mini"  v-model="formsetdate.shopAveragePrice"></el-input>
            </el-col>
          </el-form-item>
         <el-form-item label="最低消费:" >
            <el-col class="mr15" :span="3.3">
              <el-input size="mini"  v-model="formsetdate.minConsumptionAmount"></el-input>
            </el-col>
          </el-form-item>
          <div  style=" overflow: hidden; width: 1000px; ">
              <el-form-item label="门头照片" required style=" display: flex; float: left;margin: 0px 0px 0px 0px">
               <el-upload
              action=""
              :limit="1"
              :http-request="upLoadShopDoor"
              list-type="picture-card"
              :on-preview="handlePictureCardPreviewDoor"
              :on-remove="handlePictureCardRemoveDoor"
       
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisibleDoor" :append-to-body="true">
              <img width="100%" :src="dialogImageDoorUrl" alt="" />
            </el-dialog>
              <template>
              <img  :src="doorHeadPhoto" alt="" @click="ondialogimgsrc(doorHeadPhoto)" width="150px" style="margin: 10px 0px 0px 0px" >
            </template>
          </el-form-item>
         
       
            <el-form-item label="门店Logo" required style="  display: flex; float: left; margin: 0px 0px 0px 20px">
               <el-upload
              action=""
              :limit="1"
              :http-request="upLoadShopLogo"
              list-type="picture-card"
              :on-preview="handlePictureCardPreviewlogo"
             :on-remove="handlePictureCardRemoveLogo"
              :before-upload="beforeAvatarUpload"
            >
               <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisiblelog"  :append-to-body="true">
              <img width="100%" :src="dialogImagelogUrl" alt="" />
            </el-dialog>
                <template>
              <img   :src="businessLogo" alt="" @click="ondialogimgsrc(businessLogo)" width="150px" style="margin: 10px 0px 0px 0px" >
            </template>
          </el-form-item>
           
            <el-form-item label="推荐店铺图" required style="  display: flex;  float: left; margin: 0px 0px 0px 20px">
              <el-upload
              action=""
              :http-request="upbusinessShowImgUrl"
              list-type="picture-card"
              :on-preview="handlebusinessShowImgUrl"
              :on-remove="handlebusinessShowImgRemove"
              :before-upload="beforeAvatarUpload"
              >
                <i class="el-icon-plus"></i>
           
              </el-upload>
              <el-dialog :visible.sync="businessShowImgUrlVisible" :append-to-body="true">
                <img width="100%" :src="businessImageUrl" alt="" />
              </el-dialog>
                <div style="margin: 0px 0px 0px 0px">
            <template>
              <img  @click="ondialogimgsrc(businessShowImgUrl)" :src="businessShowImgUrl" alt="" width="150px" style="margin: 10px 15px 0px 0px" >
            </template>
          </div>
            </el-form-item>
            </div>
             <el-form-item label="店内图片" required>
               <el-upload
              action=""
              :limit="20"
              :http-request=" handlePictureCardPreviewStore"
              list-type="picture-card"
              :on-preview="handlePictureCardPreInPhotoList"
              :on-remove="handlePictureCardRemoveInPhotoList"
              :before-upload="beforeAvatarUpload"
            
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisiblePhoto"  :append-to-body="true">
              <img width="100%" :src="businessInPhotoUrl" alt="" />
            </el-dialog>
            <div style="margin: 0px 0px 0px 75px; "  > 
            <template>
              <img   v-for="(i,n) in formsetdate.businessInPhotoJosn" :key="n"  :src="i" alt="" @click="ondialogimgsrc(i)" width="150px" style="margin: 15px 15px 0px 0px" >
            </template>
          </div>
          </el-form-item>
              <!--门店-->
          <el-form-item label="店内分类:" required>
            <el-tooltip class="item"   effect="dark" content="“类别管理”提示：设置店铺内的商品类型，在富航生活app店铺左侧展示。" placement="top-start">
        <i class="el-icon-question" ></i>
          </el-tooltip>
            <el-button type="primary" size="mini" @click="innerVisibleroput()">添加店内分类</el-button>
            <el-table :data="gridData" style="width: 500px;">
              <el-table-column prop="orders" label="排序" width="100"></el-table-column>
              <el-table-column prop="categoryName" label="分类名称" width="150"></el-table-column>
              <el-table-column label="操作" fixed="right" min-width="120">
                <template slot-scope="scope">

                 <el-button @click="redactVisibler(scope.row.id,scope.row.businessId,scope.row.categoryName,scope.row.orders)"  size="mini" type="text">编辑</el-button>
          <el-button @click="remordactVisibler(scope.row.id,scope.row.businessId)" size="mini" type="text" >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
                <el-form-item label="店铺地址:" required>
          <p>
            {{cody.province+cody.city+cody.district+cody.address}}
       </p>
          </el-form-item>
 <el-dialog
        width="30%"
        :append-to-body="true"
        :visible.sync="innerVisibler">
        <el-form  label-width="100px">
          <el-form-item label="分类名称:" required>
              <el-input size="mini"  v-model="insertBusinessDate.categoryName" ></el-input>
          </el-form-item>
          <el-form-item label="分类排序:" required>
           <el-input size="mini"  v-model="insertBusinessDate.orders" ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer">
          <el-button @click="innerVisibler=false">取 消</el-button>
          <el-button type="primary" @click="addinsertBusiness">确 定</el-button>
        </span>
      </el-dialog>
            <!-- <el-form-item label="店铺橱窗图" required style="width: 430px">
              <el-upload
              :http-request="upWindowfigureUrl"
              list-type="picture-card"
              :on-preview="handlebindowfigureImg"
              :on-remove="handleindowfigureImgRemove"
              :before-upload="beforeAvatarUpload"
              >
              
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="indowfigureVisible" :append-to-body="true">
                <img width="100%" :src="WindowfigureUrl" alt="" />
              </el-dialog>
            </el-form-item> -->
         
             <div v-if="formsetdate.logisticsType===1">
          <el-form-item label="物流设置:" required>
             <el-tooltip class="item"   effect="dark" content="“配送管理”提示：<br/>1.配送可选顺丰配送和商家配送。选顺丰配送则由顺丰收费并负责配送，选商家配送则由商家收费并负责配送。<br/>2.商家配送可选固定配送距离和变动配送距离两种形式。"   placement="top-start">
        <i class="el-icon-question" ></i>
          </el-tooltip>
               配送
            <div >
               <el-col :span="15" class="mr15" style="margin: 0px 0px 0px 50px">
              <el-radio v-model="formsetdate.distributionType" :label="1" disabled>商家配送</el-radio>
              <span>商家最大配送距离:</span> <el-input style="width: 100px" size="mini" v-model="formsetdate.maxDistance" ></el-input> <span>公里</span></el-col> 
            <el-col style="margin: 0px 0px 0px 50px" :span="9" class="mr15"> <el-radio v-model="formsetdate.distributionType" disabled :label="0">顺丰配送</el-radio><span>顺丰最大配送距离10公里</span></el-col>
          </div>
             </el-form-item>
     <el-form-item label="店铺是否营业:"  style="width: 430px" required>
      <el-switch @change="enableBusi"  v-model="deliveryfeeTypeStatus"></el-switch>
      </el-form-item>
      <el-form-item label="配送费是否按商品规格累加:"  style="width: 430px">
      <el-switch  v-model="formsetdate.deliveryfeeTypeStatus"></el-switch>
      </el-form-item>
 <el-form-item  label="打包费是否按商品规格累加:"  style="width: 430px；">
      <el-switch  v-model="formsetdate.packageNumberAddStatus">
</el-switch>
</el-form-item>
        </div>
      <el-row v-if="formsetdate.logisticsType===2">
        <el-form-item label="物流设置:" required>
           <el-tooltip class="item"   effect="dark" content="“配送管理”提示：<br/>1.配送可选顺丰配送和商家配送。选顺丰配送则由顺丰收费并负责配送，选商家配送则由商家收费并负责配送。<br/>2.商家配送可选固定配送距离和变动配送距离两种形式。"   placement="top-start">
        <i class="el-icon-question" ></i>
          </el-tooltip>
            <span>快递</span>
         </el-form-item>
         <el-form-item label="快递商名称:" required style="width: 430px">
          <el-col class="mr15" :span="6">
          <el-input size="mini"  v-model="formsetdate.expressName" ></el-input>  
        </el-col>
  </el-form-item>
    <el-form-item label="快递费:" required style="width: 430px">
          <el-col class="mr15" :span="6">
          <el-input size="mini"  v-model="formsetdate.expressFee" ></el-input>  
        </el-col>
  </el-form-item>

          </el-row>
            <el-row v-if="formsetdate.logisticsType===3">
        <el-form-item label="物流方式:" required>
            <span>自提</span>
         </el-form-item>
 <el-form-item label="打包费是否按SKU的数量累加"  style="width: 430px">
      <el-switch
  v-model="formsetdate.packageNumberAddStatus"
 >
</el-switch>

</el-form-item>
         </el-row>
      
  <el-row v-if="formsetdate.logisticsType===4">
        <el-form-item label="物流方式:" required>
            <span>到店消费</span>
         </el-form-item>
         </el-row>
            <el-row v-if="formsetdate.logisticsType===5">
    
                 <el-form-item label="物流设置:" required>
                  上门服务
                      <!-- <el-col span="24" style=" margin-left: 25px;" >
             <span>最大服务距离:</span> <el-input style="width: 100px" size="mini" v-model="formsetdate.maxDistance" ></el-input> <span>公里</span></el-col>  -->
             </el-form-item>
          </el-row>
            <div v-if="formsetdate.logisticsType===6">
          <el-form-item label="物流设置:" required>
          配送与自提
            <div >
               <el-col span="24" class="mr15" style="margin: 0px 0px 0px 50px">
              <el-radio v-model="formsetdate.distributionType" :label="1" disabled>商家配送</el-radio>
              <span>商家最大配送距离:</span> <el-input style="width: 100px" size="mini" v-model="formsetdate.maxDistance" ></el-input> <span>公里</span></el-col> 
            <el-col style="margin: 0px 0px 0px 50px" span="10" class="mr15"> <el-radio v-model="formsetdate.distributionType" :label="0" disabled>顺丰配送</el-radio><span>顺丰最大配送距离10公里</span></el-col>
          </div>
             </el-form-item>
               <el-form-item label="配送费是否按商品规格累加:"  style="width: 430px">
      <el-switch
  v-model="formsetdate.deliveryfeeTypeStatus"
 >
</el-switch>

</el-form-item>
 <el-form-item label="打包费是否按商品规格累加:"  style="width: 430px">
      <el-switch
  v-model="formsetdate.packageNumberAddStatus"
 >
</el-switch>
</el-form-item>
        </div>
      <el-row v-if="formsetdate.logisticsType===7">
                 <el-form-item label="物流设置:" required>
                到店与上门
                      <!-- <el-col span="24" style=" margin-left: 25px;" >
             <span>最大服务距离:</span> <el-input style="width: 100px" size="mini" v-model="formsetdate.maxDistance" ></el-input> <span>公里</span></el-col>  -->
             </el-form-item>
          </el-row>
      </el-form>
         <el-dialog :visible.sync="dialogimg"  :append-to-body="true" >
              <img width="100%" :src="dialogimgsrc" alt="" />
            </el-dialog>
         <!--商品数据展示区域-->
         <div style="float: right;padding: 0 0 10px 0;">
     <el-button  :loading="sehe" @click="onaddmerchandiseDate" type="primary">保存</el-button>
     </div>
      <!--分页-->
  </el-card>
  </div>
</template>

<script>
// 引入拖动列表组件

import dataFunction from "@/utils/dataFunction";
import {getScondaryCategoryList,getGoodCategoryListByCityName,enableBusiness,delBusinessCategory,insertBusinessCategory,updateBusinessInfo, searchBusinessInfoByUserId,searchBusinessInfoById,searchBusinessCategoryListForPC} from "@/api";

export default {
  name: "UsesignList",
  data() {
    return {
      cody:"",
      required:[],
        dialogimgsrc:"",
        dialogimg:false,
       formsetdate:{},
       business:[],
       businessShowImgUrl:"",
       businessShowImgUrlVisible:false,
       businessImageUrl:"",
       dialogVisiblePhoto:false,
       sehe:false,
       redactityStatus:[],
       deliveryfeeTypeStatus:false,
       businessInPhotoUrl:"",
       businessInPhotoindexList:[],
       businessInPhotoList:[],
       whether:{

       },
       doorHeadPhoto:"",
      businessLogo:"",
       redactondaroicroin:[],
       gridData:[],
        // 添加店内分类
  insertBusinessDate:{},
  innerVisibler:false,
   dialogImageDoorUrl:"",
       dialogVisibleDoor:false,
       dialogImagelogUrl:"",
       dialogVisiblelog:false
       }
  },
  created() {
  this.searchBusinessInfoByUser(window.sessionStorage.getItem('userId'))
   
  },
methods: {
    // 点击查看logo
    handlePictureCardPreviewlogo(){
   this.dialogImagelogUrl=this.formsetdate.businessLogo
    this.dialogVisiblelog=true
  
    },
    // 删除logo图片
    handlePictureCardRemoveLogo(){
this.formsetdate.businessLogo=""
    },
    // 上传logo
      async upLoadShopLogo(file) {
       const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.formsetdate.businessLogo= res.body[0].split("?")[0];
     
      this.$message.success("上传成功");
    },
      // 查看 店内图片 
    handlePictureCardPreInPhotoList(file){
this.businessInPhotoindexList.forEach((element,i) => {
 if(element==file.uid){
   this.businessInPhotoUrl= this.businessInPhotoList[i]
   this.dialogVisiblePhoto=true
 }
 });
    },
       // 删除店内图片
    handlePictureCardRemoveInPhotoList(file){
this.businessInPhotoindexList.forEach((element,i) => {
 if(element==file.uid){
   this.businessInPhotoindexList.splice(i,1)
 this.businessInPhotoList.splice(i,1)
 } 
});

    },
    // 上传店内图片
   async handlePictureCardPreviewStore(file){
    const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
     this.businessInPhotoindexList.push(file.file.uid)
     this.businessInPhotoList.push(res.body[0].split("?")[0])
      this.$message.success("上传成功");
      },
    // 查看门头照
    handlePictureCardPreviewDoor(){
  this.dialogImageDoorUrl=this.formsetdate.doorHeadPhoto
    this.dialogVisibleDoor=true
    },
    // 删除门头照
    handlePictureCardRemoveDoor(){
   this.formsetdate.doorHeadPhoto=""
    },
  //  上传门头照
     async  upLoadShopDoor(file) {
       const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.formsetdate.doorHeadPhoto= res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
    async  enableBusi(){
      if( this.deliveryfeeTypeStatus){
       var status=1
      }else{
        var status=0
      }
  
        const e= window.sessionStorage.getItem('businessId')
const { data: res } = await enableBusiness({id:e,businessStatus:status});
if (res.code !== 200) return this.$message.error("启用失败请稍后重试");

 this.onset(e)
  },
     async  remordactVisibler(id,businessId){
      const formData = new FormData()
       formData.append("ids", id);
 const { data: res } = await delBusinessCategory(formData);
   if (res.code !== 200) return this.$message.error("删除失败请稍后重试");
   this.$message.success('删除成功')
  this.onclassifyVisible(businessId)

    },
    // 点击打开分类编辑
    redactVisibler(ids,businessI,categoryNam,order){
     const obj={
       id:ids,
       businessId:businessI,
       categoryName:categoryNam,
       orders:order
     }
     
     this.insertBusinessDate=obj
     this.innerVisibler=true
    },
    async  addinsertBusiness(){
    if(!this.insertBusinessDate.categoryName)return this.$message.error("未填写店内分类名称");
    if(!this.insertBusinessDate.orders)return this.$message.error("未填写排序");
    const { data: res } = await insertBusinessCategory(this.insertBusinessDate);
    if (res.code !== 200) return this.$message.error("添加失败请稍后重试");
    this.onclassifyVisible(this.insertBusinessDate.businessId)
    this.insertBusinessDate={}
    this.innerVisibler=false
this.$message.success('添加成功')
    },

   innerVisibleroput(){
this.insertBusinessDate.businessId=window.sessionStorage.getItem('businessId')
this.innerVisibler=true

    },
   // 点击分类设置
   async  onclassifyVisible(){
   const e= window.sessionStorage.getItem('businessId')
   const { data: res } = await   searchBusinessCategoryListForPC({businessId:e,order:"asc"});
   if (res.code !== 200) return this.$message.error("获取店内信息失败请稍后重试");
   this.gridData=res.body
    this.businessIdst=e
    this.classifyVisible = true 
    },
        ondialogimgsrc(data){

this.dialogimgsrc=data
this.dialogimg=true
    },
    async  onaddmerchandiseDate(){
     if (!this.formsetdate.businessPhone)return this.$message.error("未填写店铺电话")
      if (!this.formsetdate.shopAveragePrice) return this.$message.error("人均平均价格还未填写")
        //  if (!this.formsetdate.minConsumptionAmount) return this.$message.error("未填写最低消费")
   if (!this.formsetdate.businessShowImgUrl) return this.$message.error("推荐店铺图未空")
  //  if (!this.formsetdate.WindowfigureUrl) return this.$message.error("店铺橱窗图未空")
  //  配送验证
   if((this.formsetdate.logisticsType==1||this.formsetdate.logisticsType==6)&&this.formsetdate.distributionType==1&&!this.formsetdate.maxDistance)return this.$message.error("未填写商家配送费")
  
// 快递验证
if(this.formsetdate.logisticsType===2){
  if(!this.formsetdate.expressFee) return this.$message.error("未填写快递费")
    if(!this.formsetdate.expressName) return this.$message.error("未填写快递名称")
}
// 上门服务验证
if((this.formsetdate.logisticsType===7||this.formsetdate.logisticsType===5)&&!this.formsetdate.maxDistance)return this.$message.error("未填写上门服务距离")
//  if (!this.whether.whether1&&!this.whether.whether2) return this.$message.error("物流服务还未选择");
if(this.formsetdate.packageNumberAddStatus){
  this.formsetdate.packageNumberAddStatus=1
}else{
   this.formsetdate.packageNumberAddStatus=0
}
if(this.formsetdate.deliveryfeeTypeStatus){
  this.formsetdate.deliveryfeeTypeStatus=1
}else{
   this.formsetdate.deliveryfeeTypeStatus=0
}
 
 if((this.formsetdate.logisticsType==1||this.formsetdate.logisticsType==6)&&this.formsetdate.distributionType==0){
this.formsetdate.maxDistance=10
 }
     if(this.businessInPhotoList.length>0){
 this.formsetdate.businessInPhotoJosn=this.businessInPhotoList.join(',')
        }else{
          this.formsetdate.businessInPhotoJosn=this.formsetdate.businessInPhotoJosn.join(',')
        }


 this.sehe=true
const { data: res } = await  updateBusinessInfo(this.formsetdate);
if (res.code !== 200) return this.$message.error(res.msg);

  setTimeout(() => {
     this.sehe=false
          this.$router.go(0);
        }, 1500);
  
    },
      // 上传图片验证
    beforeAvatarUpload(file){

    if (!(file.type === 'image/jpeg' || file.type ==='image/png')){
  this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
   return false
    }
   	if ( !(file.size / 1024 / 1024 < 5)) {
			this.$message.error('上传图片大小不能超过 5MB!');
			 return false
		}
 return this.imgchecked(file)
   
    },
     imgchecked(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.readAsDataURL(file) // 必须用file.raw
        reader.onload = () => {
          // 让页面中的img标签的src指向读取的路径
          let img = new Image()
          img.src = reader.result
          img.onload = () => {
            if (!(480<=img.width<=1242&&img.height<=1920)) {
             this.$message.error('480px≤图片宽度≤1242px(图片宽度建议上传750)，0<图片高度≤1920px');
             reject()
            } else {
              resolve(true)
            }
          }
        }
      })
        },
      // 删除推荐列表图
    handlebusinessShowImgRemove(){
    this.formsetdate.businessShowImgUrl=""
    },
       // 点击查看图片
      handlebusinessShowImgUrl(){
      this.businessShowImgUrlVisible=true
         this.businessImageUrl=this.formsetdate.businessShowImgUrl
      },
      
    // 上传推荐列表图
   async upbusinessShowImgUrl(file){
    const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.formsetdate.businessShowImgUrl= res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
    //   日期选择器
       shopTime(){
       let value1=dataFunction(this.business[0])
      this.formsetdate.businessStartTime=value1.split(' ', 5)[1]
       let value2=dataFunction(this.business[1])
           this.formsetdate.businessEndTime=value2.split(' ', 5)[1]
    },
    // 获取店铺id
    async  searchBusinessInfoByUser(id){
    const { data: res } = await searchBusinessInfoByUserId({userId:id}); 
     
      window.sessionStorage.setItem('businessId',res.body.id)
       this.onset(res.body.id)
    },
  async onset(ids){
   
const { data: res } = await searchBusinessInfoById({id:ids});
if (res.code !== 200) return this.$message.error("获取商家信息错误");
this.formsetdate=res.body

if(this.formsetdate.businessEndTime&&this.formsetdate.businessStartTime){
    this.business = [
        `Sun Oct 10 2021 ${this.formsetdate.businessStartTime} GMT+0800 (中国标准时间)`,
            `Sun Oct 10 2021 ${this.formsetdate.businessEndTime} GMT+0800 (中国标准时间)`,
        ];
}else{
    this.business = [
        `Sun Oct 10 2021 00:00:00 GMT+0800 (中国标准时间)`,
            `Sun Oct 10 2021 00:00:00 GMT+0800 (中国标准时间)`,
        ];
  this.formsetdate.businessEndTime="00:00:00"
  this.formsetdate.businessStartTime="00:00:00"
} 
if(!this.formsetdate.packageNumberAddStatus==0){
  this.formsetdate.packageNumberAddStatus=true
}else{
   this.formsetdate.packageNumberAddStatus=false
}
if(!this.formsetdate.deliveryfeeTypeStatus==0){
  this.formsetdate.deliveryfeeTypeStatus=true
}else{
   this.formsetdate.deliveryfeeTypeStatus=false
}
if(this.formsetdate.businessStatus==0){
this.deliveryfeeTypeStatus=false
}else{
  this.deliveryfeeTypeStatus=true
}
        
   this.cody= JSON.parse(this.formsetdate.businessAddressJson)
 this.formsetdate.businessInPhotoJosn=this.formsetdate.businessInPhotoJosn.split(",") 
this.businessLogo=this.formsetdate.businessLogo
   this.doorHeadPhoto =this.formsetdate.doorHeadPhoto
  this.businessShowImgUrl=this.formsetdate.businessShowImgUrl
  const { data: date } = await getGoodCategoryListByCityName({cityName:this.formsetdate.city,pid:0});
  this.redactondaroicroin=date.body

   const { data: data } = await getScondaryCategoryList({id:this.formsetdate.primaryCategoryId});
   this.redactityStatus=data.body
 await this.onclassifyVisible()
  this.sehe=false

    },

    // 退出
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    // 返回首页
    backHome() {
      this.$router.push("/index");
      window.sessionStorage.removeItem("activePath");
    },
  },
};
</script>

<style scoped lang="scss">
.el-tooltip{
  margin-left: -7px;
}
.mr15 {
  margin-right: 15px;
}

p {
  margin: 0;
  padding: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
}

.content ul {
  list-style: none;

  li {
    display: flex;
    align-items: center;
    margin-top: 25px;

    span {
      font-weight: bold;
      display: block;
      width: 120px;
    }

    img {
      display: block;

      width: 100px;
    }
  }
}
.CheckInTimeTitle {
  font-size: 12px;
  color: #606266;
  margin-right: 5px;
}
.Price {
  display: flex;
  flex-wrap: wrap;
}

.resetInputSize {
  width: 100px;
}

.quill-editor {
  line-height: normal;
}

.goodDesc p img {
  width: 100px;
}
.el-buttonr {
  width: 70px;
  height: 25px;
}
.goods-details {
  .el-row {
    margin-bottom: 10px;
  }

  .goodDesc {
    p {
      img {
        width: 100px !important;
      }
    }
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-icon-s-fold,
.el-icon-s-unfold {
  font-size: 35px;
  margin: 22px;
  color: #999;
}
.mr15 {
  margin-right: 15px;
}
</style>